import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import MuiLink from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { find, findLast, first, groupBy } from 'lodash-es'
import React, { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import Link from 'next/link'
import { ActionMenu } from '@/components/molecules/interactive/ActionMenu'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { SidebarAccordion } from '@/components/molecules/interactive/SidebarAccordion'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { useIsMobile } from '@/core/hooks/useMediaQueries'
import { trimTrailingSlash } from '@/core/utils'

export interface PageNavigatorOption {
  action?: () => any
  active?: boolean
  alert?: number
  competitionId?: number
  complex?: React.ReactNode
  confirmation?: React.ReactNode
  disabled?: boolean
  disambiguatingDescription?: string
  end?: boolean
  ExpandableComponent?: ({}: any) => React.ReactNode
  href?: string
  icon?: React.ReactNode
  sameTab?: boolean
  text?: React.ReactNode
  textHoverOnly?: boolean
  hidden?: boolean
  to?: string
}

export interface PageNavigatorProps {
  storageKey?: string
  options: PageNavigatorOption[]
  skipScroll?: boolean
  onMouseEnter?: () => any
  skipExpand?: boolean
  variant: 'horizontal' | 'vertical' | 'grouped'
  iconOnly?: boolean
  tier1?: boolean
}

export const PageNavigator = (props: PageNavigatorProps) => {
  return {
    horizontal: () => <PageNavigatorHorizontal {...props} />,
    vertical: () => <PageNavigatorVertical {...props} />,
    grouped: () => <PageNavigatorHorizontal {...props} />
  }[props.variant]()
}

const PageNavigatorHorizontal = ({
  options,
  skipScroll = true
}: PageNavigatorProps) => {
  const { navTo, path } = useNavigation()
  const pathname = usePathname()
  const current =
    find(options, it => it.active) ||
    findLast(options, it => !!it.to && pathname?.includes(it.to)) ||
    find(options, it => !!it.to)
  const grouped = groupBy(options, it => it.disambiguatingDescription || it.to)

  return (
    <Tabs value={current}>
      {Object.keys(grouped).map((it, idx) => {
        const itValue = Array.from(grouped[it])
        const firstValue = first(itValue)
        const isGrouping = itValue.length > 1

        return (
          <Tab
            key={firstValue?.to}
            value={itValue.find(it => it.to === path) || firstValue}
            label={
              <Stack direction='row' spacing={2} alignItems='center'>
                <Box>
                  {isGrouping
                    ? firstValue?.disambiguatingDescription
                    : firstValue?.text}
                </Box>

                <AlertChip alert={firstValue?.alert} />
              </Stack>
            }
            component={
              isGrouping
                ? ActionMenu
                : firstValue?.confirmation
                ? ConfirmingButton
                : firstValue?.to
                ? Link
                : MuiLink
            }
            onClick={firstValue?.action}
            scroll={!skipScroll}
            prefetch={firstValue?.to ? true : undefined}
            href={firstValue?.to || firstValue?.href || '#'}
            target={
              firstValue?.href && !firstValue?.sameTab ? '_blank' : undefined
            }
            rel={
              firstValue?.href && !firstValue?.sameTab ? 'noopener' : undefined
            }
            // end={firstValue?.end}
            message={firstValue?.confirmation}
            options={
              isGrouping
                ? itValue.map((that, idx) => ({
                    key: that.to?.toString() || idx,
                    display: that.text,
                    action: async () => {
                      navTo(that.to!)
                    }
                  }))
                : []
            }
            endicon={<RcIcon icon={['fal', 'angle-down']} />}
          />
        )
      })}
    </Tabs>
  )
}

const PageNavigatorVertical = ({
  storageKey,
  options,
  skipScroll,
  skipExpand,
  onMouseEnter,
  tier1,
  iconOnly
}: PageNavigatorProps) => {
  const pathname = usePathname()
  const isMobile = useIsMobile()
  const [open, setOpen] = useLocalStorage<number>({
    key: storageKey || '',
    defaultValue: 0
  })

  // Close accordion on collapsed state
  useEffect(() => {
    if (!isMobile && iconOnly) {
      setOpen(-1)
    }
  }, [isMobile, iconOnly, setOpen])

  return (
    <Box>
      {options
        .filter(it => !it.hidden)
        .map((it, idx) => (
          <SidebarAccordion
            square
            disableGutters
            key={it.to || idx}
            expanded={!!it.ExpandableComponent && open === idx && !skipExpand}
            iconOnly={iconOnly}
            tier1={tier1}
            complex={!!it.complex}
          >
            <AccordionSummary
              key={it.to || idx}
              className={
                it.active || (it.to && pathname === trimTrailingSlash(it.to))
                  ? 'active'
                  : ''
              }
              component={
                it.confirmation ? ConfirmingButton : it.to ? Link : MuiLink
              }
              prefetch={it?.to ? true : undefined}
              scroll={it.to ? !skipScroll : undefined}
              href={it.to || it.href}
              target={it.href && !it.sameTab ? '_blank' : undefined}
              rel={it.href && !it.sameTab ? 'noopener' : undefined}
              // end={it.end}
              onClick={() => {
                it.action?.()
                setOpen(idx)
              }}
              underline='none'
              message={it.confirmation}
              onMouseOver={onMouseEnter}
              disabled={it.disabled}
              sx={{
                height: '100%',
                justifyContent: 'center',
                padding: it.complex ? 0 : undefined,
                '& .MuiTypography-root': it.textHoverOnly
                  ? {
                      opacity: 0,
                      transition: 'opacity .2s'
                    }
                  : undefined,
                '&:hover': it.textHoverOnly
                  ? {
                      '& .MuiTypography-root': {
                        opacity: 1
                      }
                    }
                  : undefined
              }}
            >
              {it.complex ? (
                it.complex
              ) : (
                <Stack
                  direction='row'
                  spacing={3}
                  alignItems='center'
                  justifyContent={it.text ? 'flex-start' : 'center'}
                  width='100%'
                  height='100%'
                  position='relative'
                  marginLeft={tier1 ? 0 : -1.4}
                >
                  <Box minWidth={20} display='flex' justifyContent='center'>
                    <PageNavigatorIcon option={it} iconOnly={iconOnly} />
                  </Box>
                  {iconOnly ? null : (
                    <>
                      <Typography
                        variant={tier1 ? 'h3' : 'body1'}
                        noWrap
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                        py={2}
                      >
                        {it.text}
                      </Typography>
                      <AlertChip alert={it.alert} />
                      {it.ExpandableComponent && !skipExpand ? (
                        <RcIconButton
                          size='small'
                          IconProps={{ size: 'xs' }}
                          icon={['fal', 'chevron-down']}
                          aria-label='expand accordion'
                          onClick={e => {
                            setOpen(idx === open ? -1 : idx)
                            e.stopPropagation()
                            e.preventDefault()
                          }}
                          // rotate 180 on open
                          sx={theme => ({
                            transform:
                              open === idx ? 'rotate(180deg)' : undefined,
                            transition: theme.transitions.create('transform', {
                              duration: theme.transitions.duration.shortest
                            })
                          })}
                        />
                      ) : null}
                    </>
                  )}
                </Stack>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {it.ExpandableComponent ? <it.ExpandableComponent /> : null}
            </AccordionDetails>
          </SidebarAccordion>
        ))}
    </Box>
  )
}

export const PageNavigatorIcon = ({
  option,
  iconOnly
}: {
  option: PageNavigatorOption
  iconOnly?: boolean
}) => {
  const icon = (
    <Badge
      invisible={!option.alert}
      variant='dot'
      color='error'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
    >
      {option.icon}
    </Badge>
  )
  return iconOnly && option.text ? (
    <Tooltip title={option.text} placement='right'>
      {icon}
    </Tooltip>
  ) : (
    icon
  )
}

const AlertChip = ({ alert }: { alert?: number }) => {
  if (!alert) return null

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 4,
        color: '#FFF',
        backgroundColor: 'error.main',
        pt: 0.3,
        px: 1.2
      })}
    >
      <Typography variant='body2' align='center'>
        {alert}
      </Typography>
    </Box>
  )
}
